import { Inject, Injectable } from '@angular/core';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';

const noop = (): any => undefined;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private isDebugMode: boolean;

  constructor(
    @Inject(ENV) private env: Environment
  ) {
    this.isDebugMode = this.env.isDebugMode;
  }

  get info() {
    if (this.isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (this.isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  error(error: Error) {
    console.error(error);
  }
}
