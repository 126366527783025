import { DOCUMENT } from '@angular/common';
import { Compiler, Inject, Injectable, Injector, Type, createNgModule } from '@angular/core';
import { LoggerService } from '@app/logger.service';

type InsertLocation = 'head' | 'body' | string;


@Injectable({
  providedIn: 'root'
})
export class LazyLoadService {
  constructor(private _compiler: Compiler, @Inject(DOCUMENT) private _document: Document, private injector: Injector, private logger: LoggerService) {}

  async loadModule(moduleDynamicImport: Promise<Type<any>>): Promise<any> {
    return moduleDynamicImport.then((elementModuleOrFactory) => {
      try {
        let factoryPromise = this._compiler.compileModuleAsync(elementModuleOrFactory);
        factoryPromise.then((_)=>{
          let moduleRef = _.create(this.injector);
          this.logger.info('Created module', moduleRef.instance);
        })
        //TODO: investigate using this method instead of the deprecated Compiler method
        // const moduleRef = createNgModule(elementModuleOrFactory, this.injector)
      } catch (err) {
        throw err;
      }
    });
  }

  static loadStyle(styleName: string, href:string = undefined, referenceElement = document.querySelector('link[href*="styles"]')): void {
    const head = document.getElementsByTagName('head')[0];
    const stylesLink: HTMLElement = document.querySelector('link[href*="styles"]');
    if (document.getElementById(styleName)) {
      //The style is already loaded
      return;
    }

    try {
      if (referenceElement) {
        const style = document.createElement('link');
        style.id = `${styleName}`;
        style.rel = 'stylesheet';
        style.href = href ? href : `${styleName}.css`;

        referenceElement.after(style);
      }
    } catch (error) {}
  }

  static normalizeCommonJSImport<T>(importPromise: Promise<T>): Promise<T> {
    // CommonJS's `module.exports` is wrapped as `default` in ESModule.
    return importPromise.then((m: any) => (m.default || m) as T);
  }

  static lazyloadScriptViaHTMLInsert(src: string, insertLocation: InsertLocation = 'body', windowEventTrigger: 'load' | 'DOMContentLoaded' = 'load'): Promise<string | void> {
    return new Promise((resolve, reject) => {
      let s, t;
      s = document.createElement('script');
      s.async = true;
      s.onload = resolve;
      s.onerror = reject;
      s.src = src;     
      if (insertLocation === 'body' || insertLocation === 'head') {
        document[insertLocation].appendChild(s);
      } else {
        t = document.querySelector(insertLocation);
        t.parentNode.insertBefore(s, t.nextSibling);
      }
    })
  }

  static lazyloadCssViaHTMLInsert(styleName: string, href:string = undefined): Promise<string | void> {
    return new Promise((resolve, reject) => {
      let s ;
      s = document.createElement('link');
      s.id = `${styleName}`;
      s.href = href ? href : `${styleName}.css`;
      s.onload = resolve;
      s.onerror = reject;
      s.rel = 'stylesheet';
      let t = document.querySelector('head');
      t.appendChild(s)
    });
  }
}
