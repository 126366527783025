import { Environment } from 'env/ienvironment';

export const environment: Environment = {
  appCode: 'WTC',
  name: 'certification',
  nameAbbreviation: 'cert',
  environmentBannerColor: '#3253A4',
  production: true,
  originURL: 'https://securecert.wilmingtontrust.com',
  pcfUrl: 'https://certmtbapigtwy.mtb.com/wealth2/v1',
  wtuOriginURL: 'https://stage-axp.wilmingtontrust.com',
  isDebugMode: true,
  passwordResetURL:
    'https://pfd-ciam-cert.wilmingtontrust.com/ext/pwdreset/Identify?AdapterId=WTCHTMLForm&TargetResource=https%3A%2F%2Fsecurecert.wilmingtontrust.com%2Flogin',
  idRecoveryURL:
    'https://pfd-ciam-cert.wilmingtontrust.com/ext/idrecovery/Recover?AdapterId=WTCHTMLForm&TargetResource=https%3A%2F%2Fsecurecert.wilmingtontrust.com%2Flogin',
  refreshURL: 'https://securecert.wilmingtontrust.com/refresh.html',
  sloLink: 'https://stage-axp.wilmingtontrust.com/logout',
  refresh_token_time: 240000,
  timeout_time: 1740000,
  issuer_uri: 'https://pfd-ciam-cert.wilmingtontrust.com',
  pingHeader: 'd3RjYXBwMmFwaToxbGEzeDYxRTVOQVhwUm8wOTJBWEg0cHRoR2JLalBQVkdVWUVjbVl6cDRoaFFFVnJrVHg5Q3ZQRWxPcVE1R2RP',
  apiTimeoutTime: 150 * 1000,
  enableTelemetry: true,
  tealiumEnv: 'qa',
  tealiumProfile: 'wtc-ui',
  EVaultDMGLink: 'https://doccustodyuicert.wilmingtontrust.com',
  logoutTargetUrl: 'https://stage-axp.wilmingtontrust.com/logout',
  impersonation: false,
  enableGainLoss: true,
  monitoringEnvironmentID: '3b310631-e777-4617-b42e-bb6aa29698b0',
  monitoringAppID: 'APPLICATION-6AC229CB9E6D7970',
  enableFeature_ydl: true,
  enableAppleSmartAppBanner: true,
  enableFeature_ydlFinApps: false,
  insightsUrl: 'https://stage-axp.wilmingtontrust.com/bin/wtu/articles?limit=3',
  enableFeature_QFXExport: true,
  enableFeature_relationshipTeamView: true,
  enableFeature_consolidatedHoldings: true,
  enableFeature_dashboardAdvertisement: true,
  enableFeature_intraday: true
};
